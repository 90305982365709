<template>
    <aside class="main-sidebar sidebar-light-olive">
        <div class="box-title" style="text-align: -webkit-center;background: white;">
            <router-link to="/" class="logo-height">
                <img src="@/assets/img/logo-text2.png" alt="AdminLTE Logo" class="wide logo-height" style="width: 90%;height: auto;position: relative;top: 50%;transform: translateY(-50%);margin-left: 20px;">
                <img src="@/assets/img/logo-no-text.png" alt="AdminLTE Logo" class="lite logo-height" style="opacity: 0.8;"></router-link>
        </div>
        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" ref="treeview" data-widget="treeview" role="menu" data-accordion="true" v-html="getSideMenu" @click="handleClick">
                    <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
                    <li class="nav-header">Examples</li>
                    <li class="nav-item">
                        <router-link to="/table" class="nav-link">
                            <i class="fas fa-circle nav-icon"></i>
                            <p>Table</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/form" class="nav-link">
                            <i class="fas fa-circle nav-icon"></i>
                            <p>Form</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>
<script>
import Vue from 'vue'
import { auth, authFetch } from '@/libs/hxcore';
import sideMenuAdmin from '@/sidemenu';
import { sideMenuSiswa, sideMenuSiswaRegister } from '@/sidemenu_siswa';
import sideMenuAdministrasi from '@/sidemenu_administrasi';
import sideMenuAkademik from '@/sidemenu_akademik';
import sideMenuFinance from '@/sidemenu_finance';
import sideMenuPR from '@/sidemenu_pr';
import sideMenuSuperUser from '@/sidemenu_superuser';
import sideMenuSystem from '@/sidemenu_system';
import sideMenuAdministrasiCabang from '@/sidemenu_administrasicabang';
import sideMenuPRCabang from '@/sidemenu_prcabang';

import $ from 'jquery';
export default {
    name: "AppLayoutMenu",
    data() {
        return {
            user: null,
            sideMenu: ''
        };
    },
    computed: {
        getAvatar: function() {
            return this.user ? this.user.avatar : ''
        },
        getSideMenu: function() {
            var info = auth.user();
            console.log('sidemenu', info)
            if (info) {
                // var output = '<li class="nav-header">MENU</li>';
                // return output+this.getWalker(info.role_id=='11'?sideMenushipper:sideMenuAdmin);
                var output = '<li class="nav-header">MENU</li>';
                var menu = null;

                //   var status = '';
                //   authFetch(`/users/siswa/${info.id}`).then(res => {
                //   res.json().then(js => {
                //     status = js.status;
                //   });
                // });

                if (info.role_id == 3) menu = sideMenuAdministrasi;
                else if (info.role_id == 12) menu = sideMenuPR;
                else if (info.role_id == 10) menu = sideMenuAkademik;
                else if (info.role_id == 11) menu = sideMenuFinance;
                else if (info.role_id == 1) menu = sideMenuSuperUser;
                else if (info.role_id == 15) menu = sideMenuSiswaRegister;
                else if (info.role_id == 21) menu = sideMenuAdministrasiCabang;
                else if (info.role_id == 22) menu = sideMenuPRCabang;
                return output + this.getWalker(menu);
            }

        }
    },
    methods: {
        handleClick(e) {
            var target = e.target.closest('.nav-link');
            if (target) {
                //target.classList.add('active');
                if (target.getAttribute('href') == '#') {
                    e.preventDefault();
                    return;
                }
                if (this.$route.path != target.getAttribute('href'))
                    this.$router.push({ path: target.getAttribute('href') })
                e.preventDefault();
            }

        },
        inMenu(menus, path) {
            if (!menus) return false;
            for (var i in menus) {
                if (menus[i].url == path) {
                    return true;
                }
            }
            return false;
        },
        getWalker(menus) {
            var output = '';
            var currPath = this.$route.path;
            for (var k in menus) {
                var menu = menus[k];
                var menuOpen = '';

                var aLink = '';
                var icon = '';
                var paragraf = '';
                var clsLink = '';
                if (currPath == menu.url) clsLink = 'active';

                if (menu.disable) {
                    aLink = '<a style="cursor:pointer;" to="' + menu.url + '" href="' + menu.url + '" class="nav-link ' + clsLink + ' ' + menu.disable + '" tabindex="-1" aria-disabled="true">';
                    icon = '<i style="color: #A6A6A4;" class="' + menu.icon + ' nav-icon"></i>';
                    paragraf = '<p style="color: #A6A6A4;">' + menu.label + (menu.menus ? '<i class="right fas fa-angle-left"></i>' : '') + '</p>';
                } else {
                    aLink = '<a style="cursor:pointer;" to="' + menu.url + '" href="' + menu.url + '" class="nav-link ' + clsLink + '">';
                    icon = '<i class="' + menu.icon + ' nav-icon"></i>';
                    paragraf = '<p>' + menu.label + (menu.menus ? '<i class="right fas fa-angle-left"></i>' : '') + '</p>';
                }
                if (this.inMenu(menu.menus, currPath)) {
                    console.log(menu);
                    menuOpen = 'menu-open';
                    clsLink = 'active';
                }

                var tmp = '<li class="' + (menu.menus ? 'nav-item has-treeview' : 'nav-item') + ' ' + menuOpen + '">';
                tmp += aLink;
                tmp += icon;
                tmp += paragraf;
                tmp += '</a>';
                if (menu.menus) {
                    tmp += '<ul class="nav nav-treeview">';
                    tmp += this.getWalker(menu.menus);
                    tmp += '</ul>';
                    console.log(menu.menus)
                }
                tmp += '</li>';
                output += tmp;
            }
            return output;
        }
    },
    created() {
        // Simulate fetching user data.
        setTimeout(() => {
            this.user = { name: `John Doe`, avatar: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y' };
        }, 2000);
    },
    mounted() {
        if (!Vue.prototype.loadMenu) {
            $(this.$refs.treeview).Treeview('init');
            Vue.prototype.loadMenu = true;
        }
    }
}
</script>
<style scoped>
.sidebar-collapse .lite {
    padding: 19px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -10px;
}
</style>