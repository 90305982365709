const routes = [
    // {
    //     path: '/riwayat',
    //     name: 'Riwayat',
    //     component: () => import('@/views/Riwayat.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/payment',
    //     name: 'Payment',
    //     component: () => import('@/views/Payment.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/cek',
    //     name: 'Cek Status',
    //     component: () => import('@/views/Checktracking.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/uploader',
    //     name: 'Buat Kiriman',
    //     component: () => import('@/views/Uploader.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/shipper',
    //     name: 'Shipper',
    //     component: () => import('@/views/Shipper.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/buyer',
    //     name: 'Buyer',
    //     component: () => import('@/views/Buyer.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/orderform',
    //     name: 'Form Order',
    //     component: () => import('@/views/Orderform.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/profile',
    //     name: 'Profile',
    //     component: () => import('@/views/Profile.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/register',
    //     name: 'Registrasi',
    //     component: () => import('@/views/Register.vue')
    // },
    // {
    //     path: '/settings/membertype',
    //     name: 'Jenis Member',
    //     component: () => import('@/views/Membertype.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/settings/courier',
    //     name: 'Data Kurir',
    //     component: () => import('@/views/Courier.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/settings/courier_service',
    //     name: 'Data Service Kurir',
    //     component: () => import('@/views/Courierservice.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true
    //     }
    // },

]

export default routes
