import Vue from 'vue'
import VueRouter from 'vue-router'
import routesExt from './router_ext'
import routesExamples from './router_examples'
import { authFetch } from '@/libs/hxcore';

Vue.use(VueRouter)

const Login = {
    template: '<div>Login</div>'
}

let routes = [
    // {
    //     path: '/',
    //     name: 'Dashboard',
    //     component: () => import('@/views/Payment.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth:true
    //     }
    // },

    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/dashboard-akademik',
        name: 'Dashboard Akademik',
        component: () => import('@/views/AkademikDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.akademik'
        }
    },
    {
        path: '/dashboard-administrasi',
        name: 'Dashboard Administrasi',
        component: () => import('@/views/AdministrasiDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.administrasi'
        }
    },
    {
        path: '/dashboard-keuangan',
        name: 'Dashboard Keuangan',
        component: () => import('@/views/KeuanganDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.accounting'
        }
    },
    {
        path: '/dashboard-pr',
        name: 'Dashboard Public Relation',
        component: () => import('@/views/PRDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.procurement'
        }
    },
    {
        path: '/dashboard-manajemen',
        name: 'Dashboard Manajemen',
        component: () => import('@/views/ManajemenDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.management'
        }
    },
    {
        path: '/dashboard-sistem',
        name: 'Dashboard Sistem',
        component: () => import('@/views/SystemDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.system'
        }
    },
    {
        path: '/dashboard-siswa',
        name: 'Dashboard Siswa',
        component: () => import('@/views/SiswaDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.siswa'
        }
    },
    {
        path: '/kalender-akademik',
        name: 'Kalender Akademik',
        component: () => import('@/views/KalenderAkademik.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.kalender'
        }
    },
    {
        path: '/user',
        name: 'Pengguna',
        component: () => import('@/views/User.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.users'
        }
    },
    {
        path: '/table',
        name: 'Table',
        component: () => import('@/views/Table.vue'),
        meta: {
            layout: 'AppLayoutAdmin'
        }
    },
    {
        path: '/tabletrans',
        name: 'Table Transient',
        component: () => import('@/views/Tabletransient.vue'),
        meta: {
            layout: 'AppLayoutAdmin'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'AppLayoutOnlyTop',
            requiresAuth: true
        }
    },
    {
        path: '/akademik-dashboard',
        name: 'Dashboard Akademik',
        component: () => import('@/views/AkademikDashboard.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.dashboard.akademik'
        }
    },
    {
        path: '/user/group',
        name: 'Grup Pengguna',
        component: () => import('@/views/UserGroup.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.roles' //core.roles
        }
    },
    {
        path: '/module',
        name: 'Permission',
        component: () => import('@/views/Module.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.module'
        }
    },
    {
        path: '/permit/:id',
        name: 'Permission',
        component: () => import('@/views/Permit.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.module'
        }
    },
    {
        path: '/logwatch',
        name: 'Log Activity',
        component: () => import('@/views/LogActivity.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.logwatch'
        }
    },
    {
        path: '/user/menu',
        name: 'Menu',
        component: () => import('@/views/Menu.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.menu'
        }
    },
    {
        path: '/user/module',
        name: 'Module',
        component: () => import('@/views/Module.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.roles'
        }
    },
    {
        path: '/siswa',
        name: 'Siswa',
        component: () => import('@/views/Siswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.masterdata.siswa'
        }
    },
    {
        path: '/register',
        name: 'Registrasi',
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/karyawan',
        name: 'Karyawan',
        component: () => import('@/views/Karyawan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.masterdata.karyawan'
        }
    },
    {
        path: '/pembagian-kelas',
        name: 'Pembagian Kelas',
        component: () => import('@/views/PembagianKelas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.kelas_siswa'
        }
    },
    {
        path: '/artikel',
        name: 'Data Artikel',
        component: () => import('@/views/Artikel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.artikel'
        }
    },
    {
        path: '/artikel/create',
        name: 'Form Tambah Artikel',
        component: () => import('@/views/FormArtikel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.artikel'
        }
    },
    {
        path: '/artikel/edit/:id',
        name: 'Form Edit Artikel',
        component: () => import('@/views/FormArtikel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.artikel'
        }
    },
    {
        path: '/mapel-materi',
        name: 'Data Induk Materi',
        component: () => import('@/views/MapelMateri.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.mapel_materi'
        }
    },
    {
        path: '/materi/create',
        name: 'Form Tambah Materi',
        component: () => import('@/views/FormMateri.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.mapel_materi'
        }
    },
    {
        path: '/materi/edit/:id',
        name: 'Form Edit Materi',
        component: () => import('@/views/FormMateri.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.mapel_materi'
        }
    },
    {
        path: '/preview-materi/:id',
        name: 'Preview Materi',
        component: () => import('@/views/PreviewMateri.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'bimbel.akademik.kelas_siswa'
        }
    },
    {
        path: '/list-ujian',
        name: 'Data Induk Soal',
        component: () => import('@/views/Ujian.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-ujian',
        name: 'Rekap Hasil Latihan dan Tryout',
        component: () => import('@/views/RekapPerProgram.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.induk_kelas'
        }
    },
    {
        path: '/rekap-ujian/kelas/:id',
        name: 'Rekap Hasil Latihan dan Tryout Per Kelas',
        component: () => import('@/views/RekapPerKelas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.induk_kelas'
        }
    },
    {
        path: '/rekap-ujian/mapel/:id/:program_id',
        name: 'Rekap Hasil Latihan dan Tryout Per Mapel',
        component: () => import('@/views/RekapPerMapel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.induk_kelas'
        }
    },
    {
        path: '/rekap-ujian/:id',
        name: 'Rekap Hasil Try Out',
        component: () => import('@/views/SiswaPerKelas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-ujian/siswa/:kelas_id/:mapel_id/:program_id',
        name: 'Detail Rekap Hasil Try out',
        component: () => import('@/views/DetailHasilUjianSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-latihan/siswa/:kelas_id/:mapel_id/:program_id',
        name: 'Detail Rekap Hasil Latihan',
        component: () => import('@/views/DetailHasilLatihanSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: 'rekap-ujian-kecermatan/siswa/ujianlist/:kelas_id/:program_id',
        name: 'Detail Rekap Hasil Latihan',
        component: () => import('@/views/DetailHasilUjianKecermatanSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-ujian-kecermatan/siswa/:kelas_id/:program_id/:paket_id',
        name: 'Detail Rekap Hasil Latihan',
        component: () => import('@/views/DetailHasilUjianKecermatanSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-ujian/siswa/ujianlist/:kelas_id/:mapel_id/:program_id',
        name: 'List ujian',
        component: () => import('@/views/RekapPerMapelUjian.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-ujian/recapt/skd/:mapel_id/:ujian_code/:program_id/:kelas_id/:paket_item',
        name: 'List rekap tryout',
        component: () => import('@/views/RekapPerMapelUjianHasilSKD.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-ujian/recapt/polri/:mapel_id/:paket_id/:program_id/:kelas_id/:paket_item',
        name: 'List rekap tryout',
        component: () => import('@/views/RekapPerMapelUjianHasilPolri.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/rekap-ujian/recapt/tpa/:mapel_id/:paket_id/:program_id/:kelas_id/:paket_item',
        name: 'List rekap tryout',
        component: () => import('@/views/RekapPerMapelUjianHasilTPA.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/ujian-kelas',
        name: 'Data Latihan & Try Out Per Program',
        component: () => import('@/views/ListUjianPerProgram.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/ujian-kelas/detail/:id',
        name: 'Detail Latihan & Try Out Per Program',
        component: () => import('@/views/DetailUjianPerProgram.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/detail-ujian/:id',
        name: 'Detail Latihan & Try Out',
        component: () => import('@/views/DetailUjian.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/detail-ujian/psikotes/:id',
        name: 'Detail Latihan & Try Out Psikotes',
        component: () => import('@/views/DetailUjianPsikologi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/detail-ujian/psikotes-detail/:id',
        name: 'Detail Tes Kecermatan',
        component: () => import('@/views/DetailSoalPsikologi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/soal/import',
        name: 'Form Import Soal',
        component: () => import('@/views/SoalImport.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/soal/create',
        name: 'Form Soal',
        component: () => import('@/views/Soal.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/soal/edit/:id',
        name: 'Form Edit Soal',
        component: () => import('@/views/Soal.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian'
        }
    },
    {
        path: '/lesson-plan',
        name: 'Lesson Plan',
        component: () => import('@/views/LessonPlan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.lesson_plan'
        }
    },
    {
        path: '/kalender-marketing',
        name: 'Kalender Marketing',
        component: () => import('@/views/KalenderMarketing.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.kalender_marketing'
        }
    },
    {
        path: '/data-guru-kunjungan',
        name: 'Data Guru Kunjungan',
        component: () => import('@/views/GuruKunjungan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.guru_kunjungan'
        }
    },
    {
        path: '/agenda-kerja',
        name: 'Agenda Kerja',
        component: () => import('@/views/AgendaKerja.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.agenda_kerja'
        }
    },
    {
        path: '/agenda-harian',
        name: 'Agenda Harian Staff',
        component: () => import('@/views/AgendaHarian.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.agenda_staff'
        }
    },
    {
        path: '/agenda-all-staff',
        name: 'Rekap Agenda Harian All Staff',
        component: () => import('@/views/AgendaAllStaff.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.agenda_staff'
        }
    },
    {
        path: '/laporan-dinas-luar',
        name: 'Laporan Dinas Luar',
        component: () => import('@/views/LaporanDinasKeluar.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.laporan_dinas_luar'
        }
    },
    {
        path: '/rekap-hasil-konsultasi',
        name: 'Rekap Hasil Konsultasi',
        component: () => import('@/views/RekapHasilKonsultasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.rekap_konsultasi'
        }
    },
    {
        path: '/pengajuan-konsultasi',
        name: 'Pengajuan Konsultasi',
        component: () => import('@/views/PengajuanKonsultasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.pengajuan_konsultasi'
        }
    },
    {
        path: '/jadwal-konsultasi',
        name: 'Jadwal Konsultasi',
        component: () => import('@/views/JadwalKonsultasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.jadwal_konsultasi'
        }
    },
    {
        path: '/rekap-pr-audience',
        name: 'Rekap PR Audience',
        component: () => import('@/views/RekapPRAudience.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.rekap_pr_audience'
        }
    },
    {
        path: '/data-sekolah',
        name: 'Data Sekolah',
        component: () => import('@/views/DataSekolah.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.data_sekolah'
        }
    },
    {
        path: '/data-sekolah-dinas',
        name: 'Data Sekolah Kedinasan',
        component: () => import('@/views/DataSekolahDinas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.kedinasan'
        }
    },
    {
        path: '/dinas-tahapan-tes/:id',
        name: 'Dinas Tahapan Tes',
        component: () => import('@/views/DinasTahapTes.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.kedinasan'
        }
    },
    {
        path: '/rekap-pembayaran-siswa',
        name: 'Rekap Pembayaran Siswa',
        component: () => import('@/views/RekapPembayaranSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.rekap_pembayaran_siswa'
        }
    },
    {
        path: '/guru',
        name: 'Data Guru',
        component: () => import('@/views/DataGuru.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.data_guru'
        }
    },
    {
        path: '/staff',
        name: 'Data Staff',
        component: () => import('@/views/DataStaff.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.data_guru'
        }
    },
    {
        path: '/data-induk-kelas',
        name: 'Data Induk Kelas',
        component: () => import('@/views/DataIndukKelas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.induk_kelas'
        }
    },
    {
        path: '/data-induk-kelas-detail/:id/:branch',
        name: 'Data Induk Kelas Detail',
        component: () => import('@/views/DataIndukKelasDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.induk_kelas'
        }
    },
    {
        path: '/data-induk-program',
        name: 'Data Induk Program',
        component: () => import('@/views/DataIndukProgram.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.induk_program'
        }
    },
    {
        path: '/data-induk-ruangan',
        name: 'Data Induk Ruangan',
        component: () => import('@/views/DataIndukRuangan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.induk_ruang'
        }
    },
    {
        path: '/data-induk-mapel',
        name: 'Data Induk Mapel',
        component: () => import('@/views/DataIndukMapel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.masterdata.mapel'
        }
    },
    {
        path: '/rekap/mapel',
        name: 'Rekap Try Out Mapel',
        component: () => import('@/views/DataIndukMapel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.masterdata.mapel'
        }
    },
    {
        path: '/unit',
        name: 'Data Induk Unit',
        component: () => import('@/views/Unit.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.masterdata.unit'
        }
    },
    {
        path: '/siswa-plan-angsuran',
        name: 'Siswa Plan Angsuran',
        component: () => import('@/views/SiswaPlanAngsuran.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.siswa_angsuran'
        }
    },
    {
        path: '/form-pengajuan-angsuran',
        name: 'Formulir Pengajuan Angsuran',
        component: () => import('@/views/FormPengajuanAngsuran.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.siswa_angsuran'
        }
    },
    {
        path: '/proposal-lpj-kegiatan',
        name: 'Proposal dan LPJ Kegiatan PR',
        component: () => import('@/views/ProposalLpjKegiatan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.proposal_lpj'
        }
    },
    {
        path: '/detail-proposal-lpj/:id',
        name: 'Detail Proposal dan LPJ Kegiatan PR',
        component: () => import('@/views/DetailProposalLpj.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.proposal_lpj'
        }
    },
    {
        path: '/approval-proposal-lpj',
        name: 'Approval Proposal & LPJ Kegiatan PR',
        component: () => import('@/views/ApprovalProposalLpj.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.proposal_lpj'
        }
    },
    {
        path: '/cabang',
        name: 'Master Cabang',
        component: () => import('@/views/Cabang.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.branch'
        }
    },
    {
        path: '/akses-cabang',
        name: 'Akses Cabang',
        component: () => import('@/views/AksesCabang.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.akses_cabang'
        }
    },
    {
        path: '/keterlambatan',
        name: 'Form Keterlambatan',
        component: () => import('@/views/Keterlambatan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.keterlambatan'
        }
    },
    {
        path: '/keterlambatan/all-staff',
        name: 'Rekap Keterlambatan',
        component: () => import('@/views/KeterlambatanAllStaff.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.keterlambatan'
        }
    },
    {
        path: '/pengajuan-ijin',
        name: 'Pengajuan Ijin',
        component: () => import('@/views/PengajuanIjin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.pengajuan_ijin'
        }
    },
    {
        path: '/pengajuan-ijin/all-staff',
        name: 'Pengajuan Ijin All Staff',
        component: () => import('@/views/PengajuanIjinAllStaff.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.pengajuan_ijin'
        }
    },
    {
        path: '/buku-tamu',
        name: 'Buku Tamu',
        component: () => import('@/views/Bukutamu.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.buku_tamu'
        }
    },
    {
        path: '/rekrutmen',
        name: 'Rekrutmen',
        component: () => import('@/views/Rekrutmen.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.rekrutmen'
        }
    },
    {
        path: '/jurnal-guru',
        name: 'Jurnal Guru',
        component: () => import('@/views/JurnalGuruList.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.jurnal_guru'
        }
    },
    {
        path: '/jurnal-guru-detail/:id',
        name: 'Jurnal Guru',
        component: () => import('@/views/JurnalGuru.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.jurnal_guru'
        }
    },
    {
        path: '/jurnal-kelas-list',
        name: 'Jurnal Kelas',
        component: () => import('@/views/JurnalKelasList.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.jurnal_kelas'
        }
    },
    {
        path: '/jurnal-kelas/:id',
        name: 'Jurnal Kelas',
        component: () => import('@/views/JurnalKelas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.jurnal_kelas'
        }
    },
    {
        path: '/staff-gaji',
        name: 'Daftar Gaji Staff',
        component: () => import('@/views/Staffgaji.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.staff_gaji'
        }
    },
    {
        path: '/guru-gaji',
        name: 'Daftar Gaji Guru',
        component: () => import('@/views/Gurugaji.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.staff_gaji'
        }
    },
    {
        path: '/penilaian-evaluasi',
        name: 'Daftar Penilaian Evaluasi',
        component: () => import('@/views/PenilaianEvaluasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.penilaian_evaluasi'
        }
    },
    {
        path: '/siswa-monitoring',
        name: 'Laporan Monitoring Siswa',
        component: () => import('@/views/SiswaMonitoring.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.siswa_monitoring'
        }
    },
    {
        path: '/perkembangan-siswa/:id',
        name: 'Laporan Perkembangan Siswa',
        component: () => import('@/views/PerkembanganSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'bimbel.akademik.siswa_monitoring'
        }
    },
    {
        path: '/log-buku-evaluasi',
        name: 'Log Buku Evaluasi',
        component: () => import('@/views/LogBukuEvaluasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.log_buku'
        }
    },
    {
        path: '/material/:id',
        name: 'Data Inventaris',
        component: () => import('@/views/MasterMaterial.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.inventaris_barang'
        }
    },
    {
        path: '/jadwal-tentor',
        name: 'Daftar Jadwal Tentor',
        component: () => import('@/views/JadwalTentor.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.jadwal_tentor'
        }
    },
    {
        path: '/pengajuan-budget',
        name: 'Pengajuan Budget',
        component: () => import('@/views/PengajuanBudget.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.pengajuan_budget'
        }
    },
    {
        path: '/pengajuan-po',
        name: 'Pengajuan PO',
        component: () => import('@/views/PengajuanPO.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.pengajuan_po'
        }
    },
    {
        path: '/pengajuan-inventaris',
        name: 'Pengajuan Inventaris',
        component: () => import('@/views/PengajuanInventaris.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.pengajuan_inventaris'
        }
    },
    {
        path: '/rekap-absensi-staff',
        name: 'Rekap Absensi Staff',
        component: () => import('@/views/RekapAbsensiStaff.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.rekap_absensi_staff'
        }
    },
    {
        path: '/rekap-absensi-guru',
        name: 'Rekap Absensi Guru',
        component: () => import('@/views/RekapAbsensiGuru.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.rekap_siswa'
        }
    },
    {
        path: '/rekap-absensi-siswa',
        name: 'Rekap Absensi Siswa',
        component: () => import('@/views/RekapAbsensiSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.rekap_guru'
        }
    },
    {
        path: '/absensi-siswa',
        name: 'Absensi Siswa',
        component: () => import('@/views/AbsensiSiswa.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.absensi_siswa'
        }
    },
    {
        path: '/absensi-guru',
        name: 'Absensi Guru',
        component: () => import('@/views/AbsensiGuru.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.absensi_guru'
        }
    },
    {
        path: '/absensi-staff',
        name: 'Absensi Staff',
        component: () => import('@/views/AbsensiStaff.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.accounting.absensi_staff'
        }
    },
    {
        path: '/absensi-jasmani',
        name: 'Absensi Jasmani',
        component: () => import('@/views/AbsensiJasmani.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.absensi_jasmani'
        }
    },
    {
        path: '/penilaian-jasmani',
        name: 'Penilaian Jasmani',
        component: () => import('@/views/PenilaianJasmani.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.penilaian_jasmani'
        }
    },
    {
        path: '/penilaian-jasmani/form',
        name: 'Form Penilaian Jasmani',
        component: () => import('@/views/FormPenilaianJasmani.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'bimbel.akademik.'
        }
    },
    {
        path: '/pengajuan-uang-makan',
        name: 'Pengajuan Makan',
        component: () => import('@/views/PengajuanUangMakan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.pengajuan_uang_makan'
        }
    },
    {
        path: '/pengajuan-maintenance',
        name: 'Pengajuan Maintenance',
        component: () => import('@/views/PengajuanMaintenance.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.administrasi.pengajuan_maintenance'
        }
    },
    {
        path: '/pengajuan-biaya',
        name: 'Pengajuan Biaya',
        component: () => import('@/views/PengajuanBiaya.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.accounting.pengajuan_biaya'
        }
    },
    {
        path: '/siswa-registrasi',
        name: 'List Registrasi Siswa',
        component: () => import('@/views/SiswaRegistrasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.accounting.siswa_registrasi'
        }
    },
    {
        path: '/jadwal-kelas',
        name: 'Jadwal Kelas',
        component: () => import('@/views/JadwalKelas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.jadwal_kelas'
        }
    },
    {
        path: '/inventaris',
        name: 'Inventaris',
        component: () => import('@/views/Inventaris.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.inventaris'
        }
    },
    {
        path: '/inventaris-detail/:id',
        name: 'Item Detail',
        component: () => import('@/views/InventarisDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.management.inventaris'
        }
    },
    {
        path: '/saldo-cabang',
        name: 'Saldo Cabang',
        component: () => import('@/views/SaldoCabang.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.accounting.siswa_registrasi'
        }
    },
    {
        path: '/keuangan-cabang',
        name: 'Kas Kecil',
        component: () => import('@/views/KeuanganCabang.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.accounting.siswa_registrasi'
        }
    },
    {
        path: '/keuangan-pusat',
        name: 'Kas Besar',
        component: () => import('@/views/KeuanganPusat.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.accounting.siswa_registrasi'
        }
    },
    {
        path: '/jurnal-umum',
        name: 'Jurnal Umum',
        component: () => import('@/views/JurnalUmum.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.accounting.siswa_registrasi'
        }
    },
    {
        path: '/ujian-paket',
        name: 'Paket Ujian',
        component: () => import('@/views/UjianPaket.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian-paket'
        }
    },
    {
        path: '/ujian-paket-detail/:id',
        name: 'Paket Ujian',
        component: () => import('@/views/UjianPaketDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.akademik.ujian-paket'
        }
    },
    {
        path: '/pengajuan-kegiatan-pr',
        name: 'Pengajuan Kegiatan PR',
        component: () => import('@/views/PengajuanKegiatanPR.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'bimbel.pr.pengajuan_pr'
        }
    },
]

routes = routes.concat(routesExt)
routes = routes.concat(routesExamples)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log('auth')
        console.log('record', to)
        //Vue.$root.$emit('onRouteChanged', 'new message!');
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!localStorage.APP_TOKEN) {
            next({ name: 'Login' })

        }
        else {
            // get permission info from server
            to.params.roles = [];
            var moduleCode = to.meta.code ? to.meta.code : '';
            authFetch('/users/profile/module', {
                method: "POST",
                body: 'code=' + moduleCode,
            })
                .then((res) => {
                    console.log(res.status)
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    }
                    else if (res.status === 403) {
                        localStorage.APP_TOKEN = '';
                        next({ path: '/login' })
                        return null;
                    }
                    return res.json();
                })
                .then((js) => {
                    if (js.success) {
                        if (js.roles.length > 0) {
                            to.params.roles = js.roles;
                        } else {
                            // return next(false)
                        }
                        //record.meta.roles = record.name+Date.now();{query:{roles:record.name+Date.now()}}
                        next({ params: to.params })
                    }
                    else if (js.status === false) {
                        localStorage.APP_TOKEN = '';
                        next({ path: '/login' })
                    }

                });

        }
    } else {
        if (localStorage.APP_TOKEN && to.path === '/login') {
            next({ path: '/' })
        }
        else next() // does not require auth, make sure to always call next()!
    }
})
export default router
