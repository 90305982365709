const routes = [{
        path: '/examples/calendar',
        name: 'About',
        component: () => import('@/views/examples/Calendar.vue'),
        meta: {
            layout: 'AppLayoutAdmin'
        }
    },
    {
        path: '/examples/form',
        name: 'Form',
        component: () => import('@/views/examples/Form.vue'),
        meta: {
            layout: 'AppLayoutAdmin'
        }
    },
    {
        path: '/examples/pdf',
        name: 'Pdf',
        component: () => import('@/views/examples/Pdfviewer.vue')
    },

]

export default routes