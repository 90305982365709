<template>
  <div v-if="isAdmin">
    <div class="wrapper">
      <AppLayoutNav />
      <AppLayoutMenu />
      <div class="content-wrapper">

      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>{{ title }}</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></a></li>
                <li class="breadcrumb-item active">{{ title }}</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <slot />
      </div>
      <footer class="main-footer">
        <div class="float-right d-none d-sm-block">
          <b>Version</b> 1.1
        </div>
        <strong>Copyright &copy; 2021 <a href="http://tarunacendekia.com">tarunacendekia.com</a>.</strong> All rights
        reserved.
      </footer>
    </div>
  </div>
  <div v-else>
    <slot />
  </div>
</template>
<script>

import AppLayoutNav from '@/layouts/AppLayoutNav'
import AppLayoutMenu from '@/layouts/AppLayoutMenu'
const defaultLayout = 'AppLayoutDefault'

export default {
  name: "AppLayout",
  data() {
    return {
      title: null,
    };
  },
  watch:{
    $route (to, from){
        this.title = to.name;
      }
  } ,
  mounted(){
    this.title = this.$route.name;
    console.log('mounted ',this.$route)
    this.$root.$on('onRouteChanged', (data) => { // here you need to use the arrow function
     console.log(data)
    })
  },
  created: function () {
    
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    },
    isAdmin(){
      console.log(this.$route.meta.layout)
      var nilai = (this.$route.meta.layout=='AppLayoutAdmin')?true:false;
      //console.log(nilai);
      return nilai;
    }
  },
  components: {
    AppLayoutNav, AppLayoutMenu
  }
}
</script>
