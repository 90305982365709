const sideMenuSuperUser = [
    { label: 'Main Menu', url: '/', icon: 'fas fa-tachometer-alt' },
    {
        label: 'Akademik',
        url: '#',
        icon: 'fas fa-chart-line',
        menus: [
            { label: 'Dashboard Akademik', url: '/dashboard-akademik', icon: 'fas fa-tachometer-alt' },
            { label: 'Kalender Akademik', url: '/kalender-akademik', icon: 'fas fa-calendar-alt' },
            { label: 'Lesson Plan', url: '/lesson-plan', icon: 'fas fa-book' },
            { label: 'Pembagian Kelas', url: '/pembagian-kelas', icon: 'fas fa-shapes' },
            { label: 'Data Induk Kelas', url: '/data-induk-kelas', icon: 'fas fa-database' },
            { label: 'Data Induk Ruangan', url: '/data-induk-ruangan', icon: 'fas fa-database' },
            { label: 'Data Induk Mapel', url: '/data-induk-mapel', icon: 'fas fa-database' },
            { label: 'Data Induk Materi', url: '/mapel-materi', icon: 'fas fa-book' },
            { label: 'Data Induk Soal', url: '/list-ujian', icon: 'fas fa-book' },
            { label: 'Data Induk Try Out', url: '/ujian-paket', icon: 'fas fa-book' },
            { label: 'Rekap Latihan & Tryout', url: '/rekap-ujian', icon: 'fas fa-table' },
            { label: 'Latihan & Try Out Program', url: '/ujian-kelas', icon: 'fas fa-book' },
            { label: 'Data Guru', url: '/guru', icon: 'fas fa-user' },
            { label: 'Data Siswa', url: '/siswa', icon: 'fas fa-user' },
            { label: 'Jadwal Tentor', url: '/jadwal-tentor', icon: 'fas fa-calendar-alt' },
            { label: 'Jadwal Kelas', url: '/jadwal-kelas', icon: 'fas fa-calendar-alt' },
            { label: 'Absensi Jasmani', url: '/absensi-jasmani', icon: 'far fa-calendar-check' },
            { label: 'Penilaian Jasmani', url: '/penilaian-jasmani', icon: 'fas fa-circle' },
            { label: 'Data Artikel', url: '/artikel', icon: 'fas fa-newspaper' },
            { label: 'Laporan Monitoring Siswa', url: '/siswa-monitoring', icon: 'fas fa-table' },
            { label: 'Data Induk Inventaris', url: '/material/'+1, icon: 'fas fa-table' },
            { label: 'Data Induk Kedinasan', url: '/data-sekolah-dinas', icon: 'fas fa-school' },
        ]
    },
    {
        label: 'Administration',
        url: '#',
        icon: 'fas fa-laptop',
        menus: [
            { label: 'Dashboard Administration', url: '/dashboard-administrasi', icon: 'fas fa-tachometer-alt' },
            { label: 'Buku Tamu', url: '/buku-tamu', icon: 'fas fa-user-shield' },
            { label: 'Rekrutmen', url: '/rekrutmen', icon: 'fas fa-user-plus' },
            { label: 'Jurnal Guru', url: '/jurnal-guru', icon: 'fas fa-book' },
            { label: 'Jurnal Kelas', url: '/jurnal-kelas-list', icon: 'fas fa-book' },
            { label: 'Absensi Siswa', url: '/absensi-siswa', icon: 'far fa-calendar-check' },
            { label: 'Absensi Guru', url: '/absensi-guru', icon: 'far fa-calendar-check' },
            { label: 'Rekap Absensi Siswa', url: '/rekap-absensi-siswa', icon: 'fas fa-table' },
            { label: 'Rekap Absensi Guru', url: '/rekap-absensi-guru', icon: 'fas fa-table' },
            { label: 'Pengajuan Budget', url: '/pengajuan-budget', icon: 'fas fa-book' },
            { label: 'Pengajuan Inventaris', url: '/pengajuan-inventaris', icon: 'fas fa-book' },
            // { label: 'Pengajuan PO', url: '/pengajuan-po', icon: 'fas fa-book' },
            { label: 'Pengajuan Uang Makan', url: '/pengajuan-uang-makan', icon: 'fas fa-book' },
            { label: 'Pengajuan Maintenance', url: '/pengajuan-maintenance', icon: 'fas fa-book' },
            //{ label: 'Data Induk Gaji Guru', url: '/guru-gaji', icon: 'fas fa-table' },
            { label: 'Data Artikel', url: '/artikel', icon: 'fas fa-newspaper' },
            { label: 'Data Induk Inventaris', url: '/material/'+2, icon: 'fas fa-table' },
            //{ label: 'Absensi Jasmani', url: '/absensi-jasmani', icon: 'far fa-calendar-check' },
            //{ label: 'Penilaian Jasmani', url: '/penilaian-jasmani', icon: 'fas fa-circle' },
        ]
    },
    {
        label: 'Public Relation',
        url: '#',
        icon: 'fas fa-bullhorn',
        menus: [
            { label: 'Dashboard PR', url: '/dashboard-pr', icon: 'fas fa-tachometer-alt' },
            { label: 'Registrasi Siswa', url: '/siswa-registrasi', icon: 'fas fa-user' },
            // { label: 'Pengajuan Angsuran Siswa', url: '/form-pengajuan-angsuran', icon: 'fas fa-table', disable: 'disabled' },
            { label: 'Data Induk Program', url: '/data-induk-program', icon: 'fas fa-database' },
            { label: 'Rekap PR Audience', url: '/rekap-pr-audience', icon: 'fas fa-table' },
            { label: 'Rekap Pembayaran Siswa', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table' },
            { label: 'Kalender Marketing', url: '/kalender-marketing', icon: 'fas fa-calendar-alt' },
            { label: 'Agenda Kerja Tim', url: '/agenda-kerja', icon: 'fas fa-list-ol' },
            { label: 'Laporan Dinas Luar', url: '/laporan-dinas-luar', icon: 'fas fa-car' },
            { label: 'Pengajuan Kegiatan PR', url: '/pengajuan-kegiatan-pr', icon: 'fas fa-book' },
            //{ label: 'Proposal & LPJ Kegiatan', url: '/proposal-lpj-kegiatan', icon: 'fas fa-tasks' },
            { label: 'Pengajuan Konsultasi', url: '/pengajuan-konsultasi', icon: 'fas fa-clipboard' },
            { label: 'Jadwal Konsultasi', url: '/jadwal-konsultasi', icon: 'fas fa-calendar-alt' },
            { label: 'Rekap Hasil Konsultasi', url: '/rekap-hasil-konsultasi', icon: 'fas fa-table' },
            { label: 'Data Sekolah', url: '/data-sekolah', icon: 'fas fa-school' },
            { label: 'Data Guru Kunjungan', url: '/data-guru-kunjungan', icon: 'fas fa-calendar-alt' },
            { label: 'Data Induk Inventaris', url: '/material/'+3, icon: 'fas fa-table' },
        ]
    },
    {
        label: 'Keuangan',
        url: '#',
        icon: 'fas fa-money-bill-wave',
        menus: [
            { label: 'Dashboard Keuangan', url: '/dashboard-keuangan', icon: 'fas fa-tachometer-alt' },
            { label: 'Rekap Pembayaran Siswa', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table'},
            { label: 'Kas Besar', url: '/keuangan-pusat', icon: 'fas fa-money-bill-wave' },
            { label: 'Kas Kecil', url: '/keuangan-cabang', icon: 'fas fa-money-bill-wave' },
            { label: 'Saldo Cabang', url: '/saldo-cabang', icon: 'fas fa-money-bill-wave' },
            { label: 'Pengajuan Biaya', url: '/pengajuan-biaya', icon: 'fas fa-book' },
            { label: 'Data Induk Inventaris', url: '/material/'+4, icon: 'fas fa-table' },
        ]
    },
    {
        label: 'Manajemen',
        url: '#',
        icon: 'fas fa-sitemap',
        menus: [
            { label: 'Dashboard Manajemen', url: '/dashboard-manajemen', icon: 'fas fa-tachometer-alt' },
            { label: 'Pengajuan Biaya', url: '/pengajuan-biaya', icon: 'fas fa-book' },
            // { label: 'Rekap Pengajuan Biaya', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table'},
            { label: 'Pengajuan Budget', url: '/pengajuan-budget', icon: 'fas fa-book' },
            // { label: 'Master Cabang', url: '/cabang', icon: 'far fa-table', disable: 'disabled' }, //if disable
            { label: 'Approval Pengajuan Angsuran', url: '/siswa-plan-angsuran', icon: 'fas fa-check-double' },
            { label: 'Approval Proposal dan LPJ', url: '/approval-proposal-lpj', icon: 'fas fa-check-double' },
            { label: 'Approval List', url: '/approval-proposal-lpj', icon: 'fas fa-check-double' },
            { label: 'Absensi Staff', url: '/absensi-staff', icon: 'fas fa-circle' },
            { label: 'Data Staff', url: '/staff', icon: 'fas fa-user' },
            { label: 'Agenda Harian Staff', url: '/agenda-harian', icon: 'fas fa-table' },
            { label: 'Rekap Agenda Harian', url: '/agenda-all-staff', icon: 'fas fa-users' },
            { label: 'Rekap Absensi Staff', url: '/rekap-absensi-staff', icon: 'fas fa-table' },
            { label: 'Log Buku Evaluasi', url: '/log-buku-evaluasi', icon: 'fas fa-table' },
            { label: 'Form Keterlambatan', url: '/keterlambatan', icon: 'fas fa-table' },
            { label: 'Rekap Keterlambatan', url: '/keterlambatan/all-staff', icon: 'fas fa-table' },
            { label: 'Pengajuan Ijin', url: '/pengajuan-ijin', icon: 'fas fa-table' },
            { label: 'Rekap Pengajuan Ijin', url: '/pengajuan-ijin/all-staff', icon: 'fas fa-table' },
            { label: 'Akses Cabang', url: '/akses-cabang', icon: 'fas fa-table' },
            { label: 'Data Induk Unit', url: '/unit', icon: 'fas fa-table' },
            { label: 'Data Induk Cabang', url: '/cabang', icon: 'fas fa-building' },
            { label: 'Data Induk Gaji Staff', url: '/staff-gaji', icon: 'fas fa-table' },
            { label: 'Data Induk Gaji Guru', url: '/guru-gaji', icon: 'fas fa-table' },
            { label: 'Data Induk Inventaris', url: '/material/'+5, icon: 'fas fa-table' },            
        ]
    },
    {
        label: 'System',
        url: '#',
        icon: 'fas fa-cogs',
        menus: [
            { label: 'Dashboard System', url: '/dashboard-sistem', icon: 'fas fa-tachometer-alt' },
            { label: 'User', url: '/user', icon: 'fas fa-users' },
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
            { label: 'Group', url: '/user/group', icon: 'fas fa-layer-group' },
            { label: 'Permission', url: '/user/module', icon: 'fas fa-key' },
            //{ label: 'Menu', url: '/user/menu', icon: 'fas fa-table' },
            { label: 'Log History', url: '/logwatch', icon: 'far fa-clock' },
        ]
    }
];
export default sideMenuSuperUser;
