const sideMenuAdministrasiCabang = [
    { label: 'Main Menu', url: '/', icon: 'fas fa-tachometer-alt' },
    {
        label: 'Akademik',
        url: '#',
        icon: 'fas fa-chart-line',
        menus: [
            { label: 'Kalender Akademik', url: '/kalender-akademik', icon: 'fas fa-calendar-alt' },
            { label: 'Lesson Plan', url: '/lesson-plan', icon: 'fas fa-book' },
            { label: 'Pembagian Kelas', url: '/pembagian-kelas', icon: 'fas fa-shapes' },
            { label: 'Data Guru', url: '/guru', icon: 'fas fa-user' },
            { label: 'Data Siswa', url: '/siswa', icon: 'fas fa-user' },
            { label: 'Jadwal Tentor', url: '/jadwal-tentor', icon: 'fas fa-calendar-alt' },
            { label: 'Jadwal Kelas', url: '/jadwal-kelas', icon: 'fas fa-calendar-alt' },            
            //{ label: 'Absensi Jasmani', url: '/absensi-jasmani', icon: 'far fa-calendar-check' },
        ]
    },
    {
        label: 'Administration',
        url: '#',
        icon: 'fas fa-laptop',
        menus: [
            { label: 'Dashboard Administration', url: '/dashboard-administrasi', icon: 'fas fa-tachometer-alt' },
            { label: 'Buku Tamu', url: '/buku-tamu', icon: 'fas fa-user-shield' },
            { label: 'Rekrutmen', url: '/rekrutmen', icon: 'fas fa-user-plus' },
            { label: 'Jurnal Guru', url: '/jurnal-guru', icon: 'fas fa-book' },
            { label: 'Jurnal Kelas', url: '/jurnal-kelas-list', icon: 'fas fa-book' },
            { label: 'Absensi Siswa', url: '/absensi-siswa', icon: 'far fa-calendar-check' },
            { label: 'Absensi Guru', url: '/absensi-guru', icon: 'far fa-calendar-check' },
            { label: 'Rekap Absensi Siswa', url: '/rekap-absensi-siswa', icon: 'fas fa-table' },
            { label: 'Rekap Absensi Guru', url: '/rekap-absensi-guru', icon: 'fas fa-table' },
            { label: 'Pengajuan Budget', url: '/pengajuan-budget', icon: 'fas fa-book' },
            { label: 'Pengajuan Inventaris', url: '/pengajuan-inventaris', icon: 'fas fa-book' },
            // { label: 'Pengajuan PO', url: '/pengajuan-po', icon: 'fas fa-book' },
            { label: 'Pengajuan Uang Makan', url: '/pengajuan-uang-makan', icon: 'fas fa-book' },
            { label: 'Pengajuan Maintenance', url: '/pengajuan-maintenance', icon: 'fas fa-book' },
            //{ label: 'Data Induk Gaji Guru', url: '/guru-gaji', icon: 'fas fa-table' },
            { label: 'Data Artikel', url: '/artikel', icon: 'fas fa-newspaper' },            
            { label: 'Absensi Jasmani', url: '/absensi-jasmani', icon: 'far fa-calendar-check' },
            { label: 'Penilaian Jasmani', url: '/penilaian-jasmani', icon: 'fas fa-circle' },
        ]
    },
    {
        label: 'Public Relation',
        url: '#',
        icon: 'fas fa-bullhorn',
        menus: [
            { label: 'Kalender Marketing', url: '/kalender-marketing', icon: 'fas fa-calendar-alt' },
            { label: 'Agenda Kerja Tim', url: '/agenda-kerja', icon: 'fas fa-list-ol' },
        ]
    },
    {
        label: 'Manajemen',
        url: '#',
        icon: 'fas fa-sitemap',
        menus: [
            { label: 'Dashboard Manajemen', url: '/dashboard-manajemen', icon: 'fas fa-tachometer-alt' },
            { label: 'Agenda Harian Staff', url: '/agenda-harian', icon: 'fas fa-table' },
            { label: 'Form Keterlambatan', url: '/keterlambatan', icon: 'fas fa-table' },
            { label: 'Pengajuan Ijin', url: '/pengajuan-ijin', icon: 'fas fa-table' },
            { label: 'Data Induk Material', url: '/material', icon: 'fas fa-table' },
        ]
    },
    {
        label: 'System',
        url: '#',
        icon: 'fas fa-cogs',
        menus: [
            { label: 'User', url: '/user', icon: 'far fa-circle' },
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
            { label: 'Group', url: '/user/group', icon: 'far fa-circle' },
            { label: 'Permission', url: '/user/module', icon: 'far fa-circle' },
            //{ label: 'Menu', url: '/user/menu', icon: 'far fa-circle' },
            { label: 'Log History', url: '/logwatch', icon: 'far fa-clock' },
        ]
    }
];
export default sideMenuAdministrasiCabang;
