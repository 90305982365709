<template>
  <nav id="sidemenu" class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item"><a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i>
      </a></li>
      <li class="nav-item">
        <!-- <form class="form-inline ml-3">
          <div class="input-group input-group-sm">
            <div class="input-group-append input-radius-left">
              <button class="btn btn-navbar" type="submit"><i class="fas fa-search"></i></button>
            </div>
            <input class="form-control form-control-navbar input-radius-right" type="search" placeholder="Search" aria-label="Search">
          </div>
        </form> -->
      </li>
      <!-- <li class="nav-item d-none d-sm-inline-block"><a href="/#" class="nav-link">Home</a>    </li>
      <li class="nav-item d-none d-sm-inline-block"><a href="/#contact" class="nav-link">Contact</a></li>
      <li class="nav-item d-none d-sm-inline-block"><a class="nav-link" href="/user">User</a></li> -->
    </ul>

    <ul class="navbar-nav ml-auto">
        <li>
          <branchSelector
            placeholder="Cabang"
            class="cabang"
            v-model="branch_id"
          />
        </li>
        <li class="hide nav-item">
          <span class="nav-link" style="padding-right:2px;">
            <div class="image d-inline-block ml-1">
              <img :src="imgUrl" class="image-header img-circle" alt="User Image"/>
            </div>
          </span>
        </li>
        <li class="nav-item">
        <router-link to="/profile" class="nav-link" style="padding-left:0;">
        {{ name }}
        </router-link>
          </li>
        <li class="nav-item">
          <a class="nav-link" href="#" v-on:click="signOut"><i class="fas fa-sign-out-alt"></i></a>
        </li>
    </ul>
  </nav>
</template>

<script>
import {auth} from '@/libs/hxcore';
import branchSelector from '@/components/BranchSelector';
export default {
  name: "AppLayoutNav",
  components: {
    branchSelector
  },
  data: function(){
    return {
      name : '',
      imgUrl : '',
      branch_id : ''
    }
  },
  created: function(){
    var info = auth.user();
    console.log('user', info)
    if(info)
    {
      this.name = info.display_name;
      this.imgUrl = info.img_url;
    }
    else{
      this.imgUrl = '/dist/img/user.jpeg';
    }
    
  },
  methods: {
    signOut: function(e) {
      console.log('signout')
      auth.logout();
      this.$router.push('/login');
      e.preventDefault();
    }
  }
}
</script>

<style scoped>

</style>
