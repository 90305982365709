const sideMenuPR = [
    { label: 'Main Menu', url: '/', icon: 'fas fa-tachometer-alt' },
    {
        label: 'Akademik',
        url: '#',
        icon: 'fas fa-chart-line',
        menus: [
            { label: 'Kalender Akademik', url: '/kalender-akademik', icon: 'fas fa-calendar-alt' },
            { label: 'Data Siswa', url: '/siswa', icon: 'fas fa-user' },
            { label: 'Data Induk Program', url: '/data-induk-program', icon: 'fas fa-database' },
        ]
    },
    {
        label: 'Administration',
        url: '#',
        icon: 'fas fa-laptop',
        menus: [
            { label: 'Buku Tamu', url: '/buku-tamu', icon: 'fas fa-user-shield' },
            { label: 'Rekrutmen', url: '/rekrutmen', icon: 'fas fa-user-plus' },
        ]
    },
    {
        label: 'Public Relation',
        url: '#',
        icon: 'fas fa-bullhorn',
        menus: [
            { label: 'Dashboard PR', url: '/dashboard-pr', icon: 'fas fa-tachometer-alt' },
            { label: 'Registrasi Siswa', url: '/siswa-registrasi', icon: 'fas fa-user' },
            { label: 'Data Induk Program', url: '/data-induk-program', icon: 'fas fa-database' },
            { label: 'Rekap PR Audience', url: '/rekap-pr-audience', icon: 'fas fa-table' },
            { label: 'Rekap Pembayaran Siswa', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table'},
            { label: 'Kalender Marketing', url: '/kalender-marketing', icon: 'fas fa-calendar-alt' },
            { label: 'Agenda Kerja Tim', url: '/agenda-kerja', icon: 'fas fa-list-ol' },
            { label: 'Laporan Dinas Luar', url: '/laporan-dinas-luar', icon: 'fas fa-car' },
            { label: 'Pengajuan Kegiatan PR', url: '/pengajuan-kegiatan-pr', icon: 'fas fa-book' },
            //{ label: 'Proposal & LPJ Kegiatan', url: '/proposal-lpj-kegiatan', icon: 'fas fa-tasks' },
            { label: 'Pengajuan Konsultasi', url: '/pengajuan-konsultasi', icon: 'fas fa-clipboard' },
            { label: 'Jadwal Konsultasi', url: '/jadwal-konsultasi', icon: 'fas fa-calendar-alt' },
            { label: 'Rekap Hasil Konsultasi', url: '/rekap-hasil-konsultasi', icon: 'fas fa-table' },
            { label: 'Data Sekolah', url: '/data-sekolah', icon: 'fas fa-school' },
            { label: 'Data Guru Kunjungan', url: '/data-guru-kunjungan', icon: 'fas fa-calendar-alt' },
        ]
    },
    {
        label: 'Keuangan',
        url: '#',
        icon: 'fas fa-money-bill-wave',
        menus: [
            { label: 'Dashboard Keuangan', url: '/dashboard-keuangan', icon: 'fas fa-tachometer-alt' },
            { label: 'Rekap Pembayaran Siswa', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table' },
            // { label: 'Rekap Pengajuan Biaya', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table', disable: 'disabled' },
        ]
    },
    {
        label: 'Manajemen',
        url: '#',
        icon: 'fas fa-sitemap',
        menus: [
            { label: 'Dashboard Manajemen', url: '/dashboard-manajemen', icon: 'fas fa-tachometer-alt' },
            { label: 'Agenda Harian Staff', url: '/agenda-harian', icon: 'fas fa-table' },
            { label: 'Log Buku Evaluasi', url: '/log-buku-evaluasi', icon: 'fas fa-table' },
            { label: 'Form Keterlambatan', url: '/keterlambatan', icon: 'fas fa-table' },
            { label: 'Pengajuan Ijin', url: '/pengajuan-ijin', icon: 'fas fa-table' },            
            { label: 'Data Induk Kedinasan', url: '/data-sekolah-dinas', icon: 'fas fa-school' },
            { label: 'Data Induk Material', url: '/material', icon: 'fas fa-table' },
        ]
    },
    {
        label: 'System',
        url: '#',
        icon: 'fas fa-cogs',
        menus: [
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
            { label: 'Log History', url: '/logwatch', icon: 'far fa-clock' },
        ]
    }
];
export default sideMenuPR;
