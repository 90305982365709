const sideMenuSiswa = [
    { label: 'Dashboard', url: '#', icon: 'fas fa-tachometer-alt' },
    {
        label: 'Akademik',
        url: '#',
        icon: 'fa fa-cog',
        menus: [
            { label: 'Kalender Akademik', url: '/kalender-akademik', icon: 'far fa-circle' },
            { label: 'Pembagian Kelas', url: '/pembagian-kelas', icon: 'far fa-circle' },
            { label: 'Lesson Plan', url: '/lesson-plan', icon: 'far fa-circle' },
            { label: 'Data Induk Kelas', url: '/data-induk-kelas', icon: 'far fa-circle' },
            { label: 'Data Induk Ruangan', url: '/data-induk-ruangan', icon: 'far fa-circle' },
        ]
    },
    {
        label: 'System',
        url: '#',
        icon: 'fas fa-cogs',
        menus: [
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
        ]
    },
];

const sideMenuSiswaRegister = [
    { label: 'Dashboard', url: '/', icon: 'fas fa-tachometer-alt' },
    {
        label: 'System',
        url: '#',
        icon: 'fas fa-cogs',
        menus: [
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
        ]
    }
];

// export default sideMenu;
export { sideMenuSiswa , sideMenuSiswaRegister };