const sideMenuFinance = [
    { label: 'Main Menu', url: '/', icon: 'fas fa-tachometer-alt' },
    {
        label: 'Akademik',
        url: '#',
        icon: 'fas fa-chart-line',
        menus: [
            { label: 'Kalender Akademik', url: '/kalender-akademik', icon: 'fas fa-calendar-alt' },
            { label: 'Data Guru', url: '/guru', icon: 'fas fa-user' },
            { label: 'Data Siswa', url: '/siswa', icon: 'fas fa-user' },
        ]
    },
    {
        label: 'Administration',
        url: '#',
        icon: 'fas fa-laptop',
        menus: [
            { label: 'Dashboard Administration', url: '/dashboard-administrasi', icon: 'fas fa-tachometer-alt' },
            { label: 'Pengajuan Budget', url: '/pengajuan-budget', icon: 'fas fa-book' },
            { label: 'Pengajuan Inventaris', url: '/pengajuan-inventaris', icon: 'fas fa-book' },
            // { label: 'Pengajuan PO', url: '/pengajuan-po', icon: 'fas fa-book' },
            { label: 'Pengajuan Uang Makan', url: '/pengajuan-uang-makan', icon: 'fas fa-book' },
            { label: 'Pengajuan Maintenance', url: '/pengajuan-maintenance', icon: 'fas fa-book' },
            { label: 'Pengajuan Biaya', url: '/pengajuan-biaya', icon: 'fas fa-book' },
        ]
    },
    {
        label: 'Public Relation',
        url: '#',
        icon: 'fas fa-bullhorn',
        menus: [
            { label: 'Rekap Pembayaran Siswa', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table' },
            { label: 'Kalender Marketing', url: '/kalender-marketing', icon: 'fas fa-calendar-alt' },
            { label: 'Agenda Kerja Tim', url: '/agenda-kerja', icon: 'fas fa-list-ol' },
            { label: 'Laporan Dinas Luar', url: '/laporan-dinas-luar', icon: 'fas fa-car' },
            { label: 'Pengajuan Kegiatan PR', url: '/pengajuan-kegiatan-pr', icon: 'fas fa-book' },
            //{ label: 'Proposal & LPJ Kegiatan', url: '/proposal-lpj-kegiatan', icon: 'fas fa-tasks' },            
        ]
    },
    {
        label: 'Keuangan',
        url: '#',
        icon: 'fas fa-money-bill-wave',
        menus: [
            { label: 'Dashboard Keuangan', url: '/dashboard-keuangan', icon: 'fas fa-tachometer-alt' },
            { label: 'Rekap Pembayaran Siswa', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table'},            
            { label: 'Pengajuan Uang Makan', url: '/pengajuan-uang-makan', icon: 'fas fa-book' },
            { label: 'Kas Besar', url: '/keuangan-pusat', icon: 'fas fa-money-bill-wave' },
            { label: 'Kas Kecil', url: '/keuangan-cabang', icon: 'fas fa-money-bill-wave' },
            { label: 'Saldo Cabang', url: '/saldo-cabang', icon: 'fas fa-money-bill-wave' },
            { label: 'Pengajuan Biaya', url: '/pengajuan-biaya', icon: 'fas fa-book' },
            { label: 'Data Induk Inventaris', url: '/material/'+4, icon: 'fas fa-table' },
        ]
    },
    {
        label: 'Manajemen',
        url: '#',
        icon: 'fas fa-sitemap',
        menus: [
            { label: 'Dashboard Manajemen', url: '/dashboard-manajemen', icon: 'fas fa-tachometer-alt' },
            // { label: 'Rekap Pengajuan Biaya', url: '/rekap-pembayaran-siswa', icon: 'fas fa-table'},
            { label: 'Agenda Harian Staff', url: '/agenda-harian', icon: 'fas fa-table' },
            { label: 'Log Buku Evaluasi', url: '/log-buku-evaluasi', icon: 'fas fa-table' },
            { label: 'Form Keterlambatan', url: '/keterlambatan', icon: 'fas fa-table' },
            { label: 'Pengajuan Ijin', url: '/pengajuan-ijin', icon: 'fas fa-table' },            
        ]
    },
    {
        label: 'System',
        url: '#',
        icon: 'fas fa-cogs',
        menus: [
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
            { label: 'Log History', url: '/logwatch', icon: 'far fa-clock' },
        ]
    }
];
export default sideMenuFinance;
