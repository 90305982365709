<template>
  <v-select :options="branchOpt" v-model="branch_id" :reduce="opt => opt.id" @input="setBranch" :clearable="false"/>
</template>
<script>
import $ from "jquery";
import { authFetch, auth } from '@/libs/hxcore';
import "bootstrap-datepicker";
//import "bootstrap-datepicker/dist/css/boostrap-datepicker.css";
import "@/assets/custom.css";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
export default {
  name: "BranchSelector",
  data: function () {
      return {
          branchOpt: [],
          mutableBranchOpt: [],
          branch_id: ''
      }
  },
  props: ['value', 'options'],
  mounted: function () {
    var self = this;
    authFetch('/management/cabang/branch_permission_list/'+ auth.user().id)
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.branchOpt = js.data;
          if (!localStorage['branch_id']) {
            localStorage['branch_id'] = js.data[0].id
            this.branch_id = js.data[0].id
          }
      })
    this.branch_id = localStorage.branch_id
  },
  beforeDestroy: function () {
    
  },
  components: {
      vSelect,
  },
  methods : {
    setBranch: function(branch) {
      authFetch('/management/cabang/set_cabang/'+branch)
        .then(res => {
            if (res.status === 201) {

            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {
            localStorage['branch_id'] = js.data
            window.location.reload()
        })
    },
  }
};
</script>
<style scoped>
  .v-select {
    width: 200px;
  }
</style>