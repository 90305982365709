const sideMenuAkademik = [
    { label: 'Main Menu', url: '/', icon: 'fas fa-tachometer-alt' },
    {
        label: 'Akademik',
        url: '#',
        icon: 'fas fa-chart-line',
        menus: [
            { label: 'Dashboard Akademik', url: '/dashboard-akademik', icon: 'fas fa-tachometer-alt' },
            { label: 'Kalender Akademik', url: '/kalender-akademik', icon: 'fas fa-calendar-alt' },
            { label: 'Lesson Plan', url: '/lesson-plan', icon: 'fas fa-book' },
            { label: 'Pembagian Kelas', url: '/pembagian-kelas', icon: 'fas fa-shapes' },
            { label: 'Data Induk Program', url: '/data-induk-program', icon: 'fas fa-database' },
            { label: 'Data Induk Kelas', url: '/data-induk-kelas', icon: 'fas fa-database' },
            { label: 'Data Induk Ruangan', url: '/data-induk-ruangan', icon: 'fas fa-database' },
            { label: 'Data Induk Mapel', url: '/data-induk-mapel', icon: 'fas fa-database' },
            { label: 'Data Induk Materi', url: '/mapel-materi', icon: 'fas fa-book' },
            { label: 'Data Induk Soal', url: '/list-ujian', icon: 'fas fa-book' },
            { label: 'Data Induk Try Out', url: '/ujian-paket', icon: 'fas fa-book' },
            { label: 'Rekap Latihan & Tryout', url: '/rekap-ujian', icon: 'fas fa-table' },
            { label: 'Latihan & Try Out Program', url: '/ujian-kelas', icon: 'fas fa-book' },
            { label: 'Data Guru', url: '/guru', icon: 'fas fa-user' },
            { label: 'Data Siswa', url: '/siswa', icon: 'fas fa-user' },
            { label: 'Jadwal Tentor', url: '/jadwal-tentor', icon: 'fas fa-calendar-alt' },
            { label: 'Jadwal Kelas', url: '/jadwal-kelas', icon: 'fas fa-calendar-alt' },
            { label: 'Absensi Jasmani', url: '/absensi-jasmani', icon: 'far fa-calendar-check' },
            { label: 'Penilaian Jasmani', url: '/penilaian-jasmani', icon: 'fas fa-circle' },
            { label: 'Data Artikel', url: '/artikel', icon: 'fas fa-newspaper' },
            { label: 'Laporan Monitoring Siswa', url: '/siswa-monitoring', icon: 'fas fa-table'},
            { label: 'Data Induk Inventaris', url: '/material/'+1, icon: 'fas fa-table' },
            { label: 'Data Induk Kedinasan', url: '/data-sekolah-dinas', icon: 'fas fa-school' },
        ]
    },
    {
        label: 'Administration',
        url: '#',
        icon: 'fas fa-laptop',
        menus: [
            { label: 'Jurnal Guru', url: '/jurnal-guru', icon: 'fas fa-book' },
            { label: 'Jurnal Kelas', url: '/jurnal-kelas-list', icon: 'fas fa-book' },
            { label: 'Penilaian Evaluasi', url: '/penilaian-evaluasi', icon: 'fas fa-book' },
            { label: 'Absensi Siswa', url: '/absensi-siswa', icon: 'fas fa-circle' },
            { label: 'Absensi Guru', url: '/absensi-guru', icon: 'fas fa-circle' },
            { label: 'Rekap Absensi Siswa', url: '/rekap-absensi-siswa', icon: 'fas fa-table' },
            { label: 'Rekap Absensi Guru', url: '/rekap-absensi-guru', icon: 'fas fa-table' },
        ]
    },
    {
        label: 'Public Relation',
        url: '#',
        icon: 'fas fa-bullhorn',
        menus: [
            { label: 'Kalender Marketing', url: '/kalender-marketing', icon: 'fas fa-calendar-alt' },
            { label: 'Agenda Kerja Tim', url: '/agenda-kerja', icon: 'fas fa-list-ol' },
        ]
    },
    {
        label: 'Manajemen',
        url: '#',
        icon: 'fas fa-sitemap',
        menus: [
            { label: 'Dashboard Manajemen', url: '/dashboard-manajemen', icon: 'fas fa-tachometer-alt' },
            { label: 'Agenda Harian Staff', url: '/agenda-harian', icon: 'fas fa-table' },
            { label: 'Log Buku Evaluasi', url: '/log-buku-evaluasi', icon: 'fas fa-table' },
            { label: 'Form Keterlambatan', url: '/keterlambatan', icon: 'fas fa-table' },
            { label: 'Pengajuan Ijin', url: '/pengajuan-ijin', icon: 'fas fa-table' },
            { label: 'Data Induk Material', url: '/material', icon: 'fas fa-table' },
        ]
    },
    {
        label: 'System',
        url: '#',
        icon: 'fas fa-cogs',
        menus: [
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
            { label: 'Log History', url: '/logwatch', icon: 'far fa-clock' },
        ]
    }
];
export default sideMenuAkademik;
